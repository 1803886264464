*,
::before,
::after {
  box-sizing: border-box;
}

:root {
  --onc-checkbox-sys-color-primary: rgb(255, 162, 25);

  --onc-navbar-color: rgb(255, 162, 25);

  --onc-signin-color: #ffa219;
  // --_font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;

  --md-sys-color-primary: #875200;
  --md-sys-color-primary-container: #ffddba;
  --md-sys-color-on-primary: #ffffff;
  --md-sys-color-on-primary-container: #2b1700;

  --md-sys-color-secondary: #875200;
  --md-sys-color-secondary-container: #ffddba;
  --md-sys-color-on-secondary: #ffffff;
  --md-sys-color-on-secondary-container: #2b1700;

  --md-sys-color-tertiary: #55633c;
  --md-sys-color-tertiary-container: #d9e9b7;
  --md-sys-color-on-tertiary: #ffffff;
  --md-sys-color-on-tertiary-container: #141f02;

  --md-sys-color-error: #ba1a1a;
  --md-sys-color-error-container: #ffdad6;
  --md-sys-color-on-error: #ffffff;
  --md-sys-color-on-error-container: #410002;
  --md-sys-color-outline: #6f7979;
  --md-sys-color-background: #fafdfc;
  --md-sys-color-on-background: #191c1c;

  --md-sys-color-surface: #fff8f4;
  --md-sys-color-on-surface: #1f1b16;
  --md-sys-color-surface-variant: #f1dfd0;
  --md-sys-color-on-surface-variant: #50453a;

  --md-sys-color-inverse-surface: #352f2a;
  --md-sys-color-inverse-on-surface: #faefe7;
  --md-sys-color-inverse-primary: #ffb866;

  --md-sys-color-shadow: #000000;
  --md-sys-color-surface-tint: #875200;
  --md-sys-color-outline-variant: #bec9c8;
  --md-sys-color-scrim: #000000;

  --md-sys-color-surface-container: hsl(38, 30%, 93%);
  --md-sys-color-surface-container-high: #ecf7fa;

  --md-sys-color-surface-container-highest: #f5f5f5;

  --my-brand-font: 'Roboto';
  --my-headline-font: 'Montserrat';
  --my-title-font: 'Montserrat';

  --md-ref-typeface-brand: var(--my-brand-font);
  --md-ref-typeface-plain: var(--my-plain-font);

  --md-sys-typescale-headline-small-font: var(--my-headline-font);
  --md-sys-typescale-headline-small-weight: 500;
  --md-sys-typescale-title-font: var(--my-title-font);
}

html {
  text-size-adjust: 100%;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}

html.ipad {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  background-color: #f4f4f4;
  background-color: var(--mdc-theme-background, #fff);
}

@media screen and (orientation: portrait) {
  body {
    width: 100vh;
    height: 100vw;
    transform: rotate(90deg) translate(0, -100vw);
    transform-origin: top left;
  }
}
