.lsi-main-content {
  --md-sys-color-secondary-container: #d0d0d0;

  padding: 0 16px;
}

.lsi-top-app-bar-logo {
  width: 32px;
}

.sign-in-page-wrapper {
  position: relative;
  z-index: 2;
  max-width: 1280px;
  margin: 0 auto;
}

.sign-in-grid-wrapper {
  display: flex;
  flex: 1 1 auto;
}

.sign-in-content-wrapper {
  width: 100%;
  max-width: 768px;
  min-height: auto;
  margin: 64px 0;

  background-color: white;
}

.sign-in-background {
  position: fixed;
  z-index: 1;
  inset: 0;

  background: rgb(36 24 0);
  background: linear-gradient(0deg, rgb(36 24 0 / 100%) 0%, rgb(121 78 9 / 100%) 35%, rgb(255 179 0 / 100%) 100%);
  background: var(--mdc-theme-background, #f4f4f4);
}

.sign-in {
  width: 100%;
  padding: 32px;
}

.sign-in-content {
  display: flex;
  flex-direction: column;
}

.sign-in-close-action {
  position: fixed;
  top: 16px;
  right: 16px;
}

.sign-in-info {
  display: flex;
  align-items: center;
}

.sign-in-info-title {
  margin-left: 1em;
  font-size: 22px;
}

.sign-in-row md-filled-button {
  --md-sys-color-primary: var(--onc-signin-color, black);
  --md-filled-button-container-shape: 9px;

  width: 50%;
}

.sign-in-field {
  margin-bottom: 16px;
}

.sign-in-input {
  padding-top: 16px;
  width: 100%;

  --md-filled-text-field-trailing-icon-color: cornflowerblue;
  --md-sys-color-surface-variant: #f5f5f5;
}

.sign-in-input .mdc-textfield {
  width: 100%;
}

.sign-in--row md-filled-button {
  --md-sys-color-primary: var(--onc-signin-color, black);
  width: 50%;

  --md-filled-button-container-shape: 9px;
}

.sign-in--field {
  margin-bottom: 16px;
}

.sign-in__input {
  padding-top: 16px;
  width: 100%;

  --md-filled-text-field-trailing-icon-color: cornflowerblue;
}

.sign-in--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.sign-in--navlink {
  color: cornflowerblue;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  bottom: -5px;
}

.sign-in--navlink:hover {
  color: blue;
  text-decoration: underline;
}

.sign-in-instructions {
  margin-bottom: 32px;
  font-size: 1.25rem;
}

.sign-in-action {
  width: 100%;
  margin-left: 0;
  --md-sys-color-primary: var(--onc-signin-color, #808080);
  --md-filled-button-container-shape: 9px;
}

.lsi-headline4 {
  font-family: Manrope, Roboto, sans-serif;
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 2.5rem;
  text-decoration: inherit;
  text-transform: inherit;
  letter-spacing: 0.0074em;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.lsi-field-notifications {
  margin: 0;
  padding: 0;
  padding-top: 8px;

  font: inherit;
  font-size: 100%;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: black;
  vertical-align: baseline;

  border: 0;
}

.lsi-field-notifications-prompt {
  margin: 0;
  padding: 0;

  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.125rem;
}

.lsi-field-notification {
  margin: 0;
  padding: 0;

  font: inherit;
  font-size: 100%;
  vertical-align: baseline;

  border: 0;
}

.lsi-field-notification-symbol {
  margin: 0;
  margin-right: 4px;
  padding: 0;

  font: inherit;
  font-size: 100%;
  vertical-align: baseline;

  border: 0;
}
